<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const { data: appInitData } = useAppInitData();
const { depositInfoData, durationLeft, timerWheelDisabled } = useDepositStreakData({ immediate: !isGuest.value });

const prepareImgUrl = useImage();
const imageBgLeaves = `url(${prepareImgUrl(`/nuxt-img/wheel-of-treasure/promo-bg.png`, {
	format: "webp",
	width: 438,
	height: 294
})})`;

const buttonName = computed(() => {
	if (isGuest.value) {
		return t("Create an Account");
	}
	if (depositInfoData.value.secondsToResetStreakCounter) {
		return timerWheelDisabled.value;
	}
	if (depositInfoData.value.canWheel) {
		return t("Spin & Win");
	}
	return t("Make purchase");
});

const handleCardClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/wheel-of-treasure", { external: true });
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleButtonClick = (event: MouseEvent) => {
	event?.stopPropagation();
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				button_name: "make_purchase",
				location: "promotions"
			});
			if (depositInfoData.value.canWheel) {
				open("LazyOModalWheelOfTreasurePlayWheel");
				return;
			}
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<ACard
		class="card-wheel-of-treasure"
		width="292px"
		height="380px"
		:imgAttrs="{
			width: 292,
			height: 380,
			src: '/nuxt-img/wheel-of-treasure/promo-card.png',
			alt: 'wheel-of-treasure',
			format: 'webp',
			loading: 'lazy'
		}"
		@click="handleCardClick"
	>
		<AText class="countdown" :modifiers="['uppercase', 'center']" as="div">
			<template v-if="isGuest">{{ t("Register to Play") }}</template>
			<template v-else>
				{{ t("Ends in") }}&nbsp;<b>{{ durationLeft }}</b>
			</template>
		</AText>
		<template #bottom>
			<MWheelOfTreasureFakeWheel
				class="wheel"
				:wheelSize="260"
				:isPercentEnabled="appInitData?.depositStreak?.percentEnabled"
			/>
			<div class="content">
				<AText class="shadow text-caracas" variant="taipei" :modifiers="['bold']" as="div">
					{{ t("Wheel of Treasure") }}
				</AText>
				<AText class="shadow description" variant="toledo" :modifiers="['semibold']" as="div">
					{{ t("Get Treasures After Every 8 Purchases!") }}
				</AText>
				<MWheelOfTreasureStepsProgressBar showNumber />
			</div>
			<AButton
				:class="['btn', { disabled: depositInfoData.secondsToResetStreakCounter }]"
				variant="primary"
				size="xl"
				@click="handleButtonClick"
			>
				<AText variant="turin" :modifiers="['medium']">
					{{ buttonName }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-wheel-of-treasure.card.default {
	text-align: center;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		background: v-bind(imageBgLeaves) no-repeat top left / contain;
		background-position: 0 4px;
		z-index: 2;
	}
	.shadow {
		text-shadow: 0 0 30px var(--ciciliya);
	}
	.description {
		margin-top: gutter(1);
	}
	.countdown {
		position: relative;
		padding: gutter(1);
		width: calc(100% - 24px);
		height: 34px;
		background: var(--changshu);
		margin: 0 auto;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		z-index: 3;
	}
	.wheel {
		position: absolute;
		top: 10px;
		padding: gutter(2);
		z-index: -1;
		.animation-drum {
			z-index: -2;
		}
	}
	.content {
		position: relative;
		z-index: 10;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: -1;
			background: linear-gradient(
				180deg,
				rgba(4, 121, 229, 0) 30.06%,
				#0479e5 51.18%,
				#0479e5 91.86%,
				rgba(4, 121, 229, 0) 103.94%
			);
			width: 100%;
			height: 285px;
		}
	}
	.progress {
		border-radius: 0;
		width: 100%;
		margin: gutter(2) 0 gutter(3);
		background: var(--crawley);
		:deep(.step) {
			padding: gutter(0.25) gutter(1.3125);
		}
	}

	.btn.primary {
		width: 100%;
		position: relative;
		z-index: 2;
	}
}
</style>
